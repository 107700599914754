// single place to change game data stuff
export const games = [
  {
    game: 53,
    entityName: '4',
    competitionId: 386,
    gameName: 'Guinness 6N CIC - IRE',
    leagueType: 'round',
  },
  {
    game: 45,
    entityName: '5',
    competitionId: 386,
    gameName: 'Guinness 0.0 CIC - EPL',
    leagueType: 'round',
  },
  {
    game: 46,
    entityName: '6',
    competitionId: 386,
    gameName: 'Guinness CIC - AUS',
    leagueType: 'round',
  },
  {
    game: 48,
    entityName: '8',
    competitionId: 58,
    gameName: 'AFL - Carlton Dry',
    leagueType: 'round',
  },
  {
    game: 50,
    entityName: '9',
    competitionId: 58,
    gameName: 'AFL - Carlton Draught',
    leagueType: 'round',
  },
  {
    game: 51,
    entityName: '10',
    competitionId: 59,
    gameName: 'NRL (Queensland) - Victoria Bitter',
    leagueType: 'round',
  },
  {
    game: 52,
    entityName: '11',
    competitionId: 59,
    gameName: 'NRL - Victoria Bitter',
    leagueType: 'round',
  },
];

export const quizzes = [
  {
    id: 2,
    entityName: 'quiz',
    name: 'FANZO Quiz',
  },
  {
    id: 3,
    entityName: 'frenchQuiz',
    name: 'FANZO French Quiz',
  },
  {
    id: 6,
    entityName: 'aussieQuiz',
    name: 'FANZO Australia Quiz',
  },
  {
    id: 4,
    entityName: 'NRLQuiz',
    name: 'NRL Quiz',
  },
  {
    id: 5,
    entityName: 'aflQuiz',
    name: 'AFL Quiz',
  },
  {
    id: 7,
    entityName: 'ieQuiz',
    name: 'IE Quiz',
  },
];
