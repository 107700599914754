/* eslint-disable react/jsx-props-no-spreading */
/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as types from './types';

// Different Form fields.
import BooleanField from './Boolean';
import DateField from './Date';
import LongTextField from './LongText';
import NumberField from './Number';
import TextField from './Text';
import ReadOnly from './ReadOnly';
import TimeField from './Time';
import DateTime from './DateTime/dateTime';
import FileUpload from '../FileUpload/FileUpload';
import { get } from '../../utils/object';
import { getBase64FromFile } from '../../utils/helpers';
import EnumField from '../EnumField';
import AutoCompleteTextField from '../AutoCompleteTextField';
import AutoInAndOutSelector from '../AutoInAndOutSelector';
import TranslationField from '../TranslationField/TranslationField';
import ColorPickerWithPreview from '../ColorPickerWithPreview';
import { fileUploadAcceptTypes } from '../../utils/globalVariables';

const FormField = ({
  editedValue,
  description,
  error,
  icon,
  initialValue,
  onValueChange,
  parameters,
  fullResource: { id },
}) => {
  if (!description || !description.type) {
    return null;
  }
  // Label of the field.
  const label = description.label || '';

  // Help for tooltip.
  const help = description.help || null;

  // Format error messages.
  const errorText = Array.isArray(error) ? error.join(', ') : error;

  // Text Max length.
  const maxLengthText = get(['options', 'maxLength'], description);

  // Enum.
  const enumValues = get(['options', 'enum'], description);

  const getAutocompleteUrl = (baseAutoCompleteURL, search) => {
    const append = `limit=10&search=`;
    const url = !baseAutoCompleteURL.includes('?')
      ? `${baseAutoCompleteURL}?${append}`
      : `${baseAutoCompleteURL}&${append}`;

    return `${url}${search}`;
  };

  const formatAutoCompleteText = entity => {
    if (entity.autocompleteValue) {
      return {
        text: entity.autocompleteValue,
        value: entity.id,
      };
    }
    if (entity.name) {
      return {
        text: `${entity.name}: (${entity.id})`,
        value: entity.id,
      };
    }
    if (entity.firstName && entity.lastName) {
      return {
        text: `${entity.firstName} ${entity.lastName} (${entity.id})`,
        value: entity.id,
      };
    }
    return {
      text: `ID: ${entity.id}`,
      value: entity.id,
    };
  };

  const uploadFile = (file, successCallback, errorCallback) =>
    getBase64FromFile(file)
      .then(base64 => {
        onValueChange(base64);
        successCallback();
      })
      .catch(errorCallback);

  if (description?.options?.readOnly) {
    return <ReadOnly name={label} text={editedValue || description.options.defaultValue} />;
  }

  if (enumValues) {
    return (
      <EnumField
        availableValues={Object.keys(enumValues).map(elm => ({
          key: elm,
          value: enumValues[elm],
        }))}
        label={label}
        error={errorText}
        onChange={onValueChange}
        value={`${editedValue}`}
      />
    );
  }
  switch (description.type) {
    case types.INOUTSELECTOR: {
      /**
    @shape const InAndOutDescription = {
        options: {
          getActive: `members/roles?forMember=222673&`,
          getAvailable: `members/roles?notForMember=222673&`,
          add: '...',
          remove: '...',
        },
        removeItems: 'Active Items',
        addItems: 'Add Items',
        disableSearch: true
      };

      @etc data has to have format of { id: NUM, name: STRING }
      */

      return (
        <AutoInAndOutSelector
          requestBodyName={description.requestBodyName}
          disableSearch={description.disableSearch}
          activeItemsString={description.removeItems}
          availableItemsString={description.addItems}
          formatName={data =>
            data.map(item => ({
              id: item.id,
              name: item.name,
            }))
          }
          endpoints={{
            fetchActive: description.options.getActive,
            fetchAvailable: description.options.getAvailable,
            add: description.options.add,
            remove: description.options.remove,
          }}
        />
      );
    }
    case types.HEXCOLOR: {
      const field = (
        <ColorPickerWithPreview
          title={label}
          help={help}
          handleChangeColor={onValueChange}
          currentColor={editedValue}
        />
      );
      if (description?.options?.translatable && parameters) {
        return (
          <div className="pb-2 pt-4">
            <p className="text-darkGrey">{label}</p>
            <TranslationField
              type={description.type}
              defaultValue={editedValue}
              defualtField={field}
              fieldName={description.name}
              label={label}
              endpoint={parameters.endpoint}
              id={id}
              help={help}
            />
          </div>
        );
      }
      return field;
    }
    case types.VIDEO: {
      return (
        <FileUpload
          acceptType={fileUploadAcceptTypes.video}
          label={label}
          filePath={editedValue}
          error={errorText}
          help={help}
          processFile={uploadFile}
          deleteAction={onValueChange}
        />
      );
    }
    case types.IMAGE: {
      const field = (
        <FileUpload
          label={label}
          acceptType={fileUploadAcceptTypes.image}
          filePath={editedValue}
          error={errorText}
          help={help}
          processFile={uploadFile}
          deleteAction={onValueChange}
        />
      );
      if (description?.options?.translatable && parameters) {
        return (
          <TranslationField
            type={description.type}
            defaultValue={editedValue}
            defualtField={field}
            fieldName={description.name}
            label={label}
            endpoint={parameters.endpoint}
            id={id}
            help={help}
          />
        );
      }
      return field;
    }
    case types.STRING: {
      if (description?.options?.autoComplete) {
        return (
          <AutoCompleteTextField
            editEntityRoute={description.options.autoComplete}
            dataSource={search => getAutocompleteUrl(description.options.autoComplete, search)}
            formatDataSource={entities => entities.map(entity => formatAutoCompleteText(entity))}
            label={label}
            onValueChange={selectedValue => onValueChange(selectedValue)}
            searchText={editedValue}
          />
        );
      }

      const textFieldProps = {
        error: errorText,
        handleEditField: onValueChange,
        help,
        maxTextLength: maxLengthText,
        placeholder: label,
        name: label,
        text: editedValue || '',
      };

      if (description?.options?.translatable && parameters) {
        return (
          <TranslationField
            defaultValue={editedValue}
            type={description.type}
            defualtField={
              <TextField containerClassName="TranslationFields_input" {...textFieldProps} />
            }
            fieldName={description.name}
            label={label}
            help={help}
            endpoint={parameters.endpoint}
            id={id}
          />
        );
      }
      return <TextField {...textFieldProps} />;
    }
    case types.LONG_TEXT:
      return (
        <LongTextField
          error={errorText}
          handleEditField={onValueChange}
          help={help}
          maxLengthText={maxLengthText}
          name={label}
          previousText={initialValue}
          text={editedValue || ''}
        />
      );

    case types.FLOAT:
    case types.INTEGER: {
      if (description?.options?.autoComplete) {
        return (
          <AutoCompleteTextField
            editEntityRoute={description.options.autoComplete}
            dataSource={search => getAutocompleteUrl(description.options.autoComplete, search)}
            formatDataSource={entities => entities.map(entity => formatAutoCompleteText(entity))}
            label={label}
            onValueChange={selectedValue => onValueChange(selectedValue)}
            searchText={editedValue}
          />
        );
      }
      return (
        <NumberField
          error={errorText}
          handleEditField={onValueChange}
          help={help}
          name={label}
          value={editedValue}
        />
      );
    }
    case types.DATE:
      return (
        <DateField
          date={editedValue}
          error={errorText}
          handleEditField={onValueChange}
          help={help}
          name={label}
        />
      );
    case types.TIME:
      return (
        <TimeField help={help} name={label} onValueChange={onValueChange} value={editedValue} />
      );
    case types.BOOLEAN:
      return (
        <BooleanField
          data={editedValue}
          handleEditField={onValueChange}
          help={help}
          name={label}
          icon={icon}
        />
      );
    case types.DATETIME:
      return (
        <DateTime
          dateTime={editedValue}
          error={errorText}
          handleEditField={onValueChange}
          help={help}
          name={label}
        />
      );
    case types.UTCDATETIME: {
      const field = (
        <DateTime
          dateTime={editedValue}
          error={errorText}
          handleEditField={val => onValueChange(moment.unix(moment(val).valueOf() / 1000).format())}
          help={help}
          name={label}
        />
      );
      if (description?.options?.translatable && parameters) {
        return (
          <div className="my-2">
            <TranslationField
              type={description.type}
              defaultValue={editedValue}
              defualtField={field}
              fieldName={description.name}
              label={label}
              endpoint={parameters.endpoint}
              id={id}
              help={help}
            />
          </div>
        );
      }
      return field;
    }
    case types.CSV: {
      return (
        <FileUpload
          acceptType={fileUploadAcceptTypes.csv}
          label={label}
          error={errorText}
          help={help}
          processFile={uploadFile}
          overridePreviewComponent={<p>{editedValue}</p>}
        />
      );
    }
    default:
      // eslint-disable-next-line no-console
      console.error(
        `No FormField has been found for the type "${description.type}" for the field "${label}"`,
      );
      return null;
  }
};

FormField.propTypes = {
  editedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  renderKey: PropTypes.string.isRequired,
  description: PropTypes.shape({
    help: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    disableSearch: PropTypes.bool,
    name: PropTypes.string,
    requestBodyName: PropTypes.string,
    addItems: PropTypes.string,
    removeItems: PropTypes.string,
    add: PropTypes.func,
    remove: PropTypes.func,
    options: PropTypes.oneOfType([
      PropTypes.shape({
        readOnly: PropTypes.bool,
        getAvailable: PropTypes.func,
        autoComplete: PropTypes.string,
        translatable: PropTypes.bool,
        getActive: PropTypes.func,
        add: PropTypes.string,
        remove: PropTypes.string,
        defaultValue: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.shape()),
    ]),
  }),
  error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  icon: PropTypes.node,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  onValueChange: PropTypes.func.isRequired,
  parameters: PropTypes.shape().isRequired,
  fullResource: PropTypes.shape().isRequired,
  entityName: PropTypes.string.isRequired,
};

FormField.defaultProps = {
  description: null,
  editedValue: null,
  error: null,
  icon: null,
  initialValue: null,
};

export default FormField;
