import createFormPage from '../formPage';
import CountriesSelector from './component/countriesSelector';
import GameOnboardingTile from './component/GameOnboardingTile';

const params = {
  endpoint: 'games',
  sections: {
    General: [
      'active',
      'name',
      'competitionId',
      'type',
      'leagueRowImages',
      'sportType',
      'tournament',
    ],
    'Common Theme': ['theme', 'logo', 'gameBackgroundImage'],
    'Homepage Tile': [
      'style.homepageBackgroundImage',
      'buttonText',
      'buttonColour',
      'buttonTextColour',
    ],
    'STW Config': [
      'spinToWinFirstFrame',
      'spinToWinGif',
      'spinToWinSubtitle',
      'lightLogo',
      'winPrizeBackground',
      'losePrizeBackground',
    ],
    'Prediction Config': [
      'teamLogoType',
      'scoring',
      'hasPintGifting',
      'allowPredictionCompare',
      'allowFreePotToggle',
      'prizesHeaderBgImage',
      'roundHeaderSubtitle',
      'myRoundsText',
      'myPrizesText',
      'prizeTabClaimText',
      'prizeModalClaimLine1',
      'prizeModalClaimLine2',
      'prizeModalGiftLine1',
      'prizeModalGiftLine2',
      'predictionTextPlural',
      'predictionTextSingular',
    ],
    'Onboarding Tiles': [
      {
        fieldPath: 'onBoardingTiles',
        Component: GameOnboardingTile,
      },
    ],
    countries: [{ fieldPath: 'id', Component: CountriesSelector }],
    'Terms and conditions': ['strapiThirdPartyTermsId', 'termsConditionsUrl'],
  },
  create: {
    fields: ['name', 'active', 'competitionId'],
  },
};

const gamesPage = createFormPage('game', params);

export default gamesPage;
