import React from 'react';
import PropTypes from 'prop-types';

const Users = ({ editedValue }) => (
  <>
    <p>Please use Pogba if you wish to update contacts</p>
    <div>
      <div className="flex gap-2">
        <p>id</p>
        <p>email</p>
        <p>name</p>
        <p>phoneNumber</p>
        <p>role</p>
      </div>
      {editedValue &&
        editedValue?.map(({ email, name, id, role, phoneNumber }) => (
          <div className="flex gap-2" key={id}>
            <p>{id}</p>
            <p>{email}</p>
            <p>{name}</p>
            <p>{phoneNumber}</p>
            <p>{role}</p>
          </div>
        ))}
    </div>
  </>
);

Users.propTypes = {
  editedValue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Users;
